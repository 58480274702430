import { useRouter } from 'next/router';
import Link from 'next/link';
// Utils
import { routes } from '@/routes/routes';
import { brand } from '@/brand/brand';
// Styles & Icons
import { ArrowRightLong } from '@/assets/icons/iconComponents/ArrowRightLong';
import styles from '../pages/login/login.module.css';

export function useLogin() {
  const router = useRouter();

  const getRouting = () => {
    return router?.query?.redirect
      ? { path: router?.query?.redirect.split('?')[0] }
      : { path: brand.loggedInHomeRoute }; //check url to see if user came from somewhere specific, if not use brand home page
  };

  const handleLinkClick = (link) => {
    router.push(link);
  };

  const handleRememberMe = ({ target: { checked } }, setFormState) => {
    setFormState((prevState) => ({
      ...prevState,
      rememberCheck: checked,
    }));
  };

  const handleSetLoginQuery = () => {
    const { path } = getRouting();
    const { ...rest } = router.query;
    const queryParameters = Object.entries(rest).reduce(
      (acc, [key, value]) => ({
        ...acc,
        ...(value && { [key]: value }),
      }),
      {}
    );
    router.replace({
      pathname: path,
      query: {
        ...queryParameters,
      },
    });
  };

  const errorMessages = {
    password: {
      error: true,
      header: 'Incorrect Password.',
      body: "We're sorry, that wasn't the right password. Try again or reset your password.",
      message: (
        <>
          <Link href={routes.forgotPassword.path}>
            <a className={styles.resetLink} data-core-qa="errorButton">
              Reset Password
              <ArrowRightLong className={styles.arrowIconErr} />
            </a>
          </Link>
        </>
      ),
    },
    accountLocked: {
      error: true,
      header: 'Account Locked.',
      body: 'Access to your account is currently restricted. For assistance in restoring access, please reach out to our customer support team.',
    },
    tempDisabled: {
      error: true,
      header: 'Account Temporarily Disabled.',
      body: "We've temporarily locked your account for security reasons due to multiple login attempts. Please try again later, or you can reset your password for immediate access.",
    },
    unverified: {
      error: true,
      header: 'Email Not Verified.',
      body: "We're sorry, this email has not been verified. Please check your email for a confirmation link.",
    },
    notFound: {
      error: true,
      header: 'Invalid Email.',
      body: "We're sorry, there is no account associated with that email address.",
      message: (
        <>
          <p className={styles.errorButtonRow}>
            Verify your email or
            <Link href={routes.signup.path}>
              <a className={styles.errorLink} data-core-qa="errorButton">
                Sign Up <ArrowRightLong className={styles.arrowIconErr} />
              </a>
            </Link>
          </p>
        </>
      ),
    },
    creds: {
      error: true,
      header: 'Invalid Credentials.',
      body: 'Please verify the information entered. Try again or reset your password.',
      message: (
        <>
          <Link href={routes.forgotPassword.path}>
            <a className={styles.resetLink} data-core-qa="errorButton">
              Reset Password
              <ArrowRightLong className={styles.arrowIconErr} />
            </a>
          </Link>
        </>
      ),
    },
    generic: {
      error: true,
      header: 'Unexpected Error.',
      body: 'Something has gone wrong, please try again.',
    },
  };

  return {
    handleLinkClick,
    handleRememberMe,
    handleSetLoginQuery,
    errorMessages,
  };
}
