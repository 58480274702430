import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import * as auth from '@nerdcoresdk/nerd-core-auth';
// Components
import { ArrowRightLong } from '@/assets/icons/iconComponents/ArrowRightLong';
import { AlertBody, LoadingSpinner, PasswordInputIcon } from '@/common/index';
import TwoFactorAuthModal from '@/components/TwoFactorAuthModal';
// Hooks
import { useAuth } from '@/hooks/useAuth';
import { useLogin } from '@/hooks/useLogin';
import { useFeatureFlags } from '@/providers/FeatureFlagProvider';
// Utils
import { brand } from '@/brand/brand';
import { routes } from '@/routes/routes';
import { getHasValidEmail } from '@/utils/validation';
import { config } from 'config';
// Styles
import styles from './login.module.css';

const initialSessionState = {
  show2FAModal: false,
  session: {},
};

const initialAppAuth = {
  appEnabled: false,
  userToken: '',
  show2FAModal: false,
};

const initialErrorState = {
  error: false,
  severity: 'error',
  header: '',
  message: '',
};

const initialFormState = {
  email: { value: '', error: false, message: '' },
  password: { value: '', error: false, message: '' },
  rememberCheck: false,
};

export default function Login() {
  const { signin, sendVerificationEmail } = auth.getInstance();
  const router = useRouter();
  const { setCreateRememberMeToken } = useAuth();
  const { handleSetLoginQuery, handleRememberMe, errorMessages } = useLogin();
  const { featureFlags } = useFeatureFlags();
  const [error, setError] = useState(initialErrorState);
  const [formState, setFormState] = useState(initialFormState);
  const [sessionState, setSessionState] = useState(initialSessionState);
  const [appAuth, setAppAuth] = useState(initialAppAuth);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [espEnabled, setEspEnabled] = useState(false);

  const isShopping = router?.query?.cart; // used for login page header to dynamically populate

  const useEmail2fa = featureFlags['Membership.Authentication.UseEmail2fa'];

  const close2FAModal = () => {
    setSessionState(initialSessionState);
    setAppAuth(initialAppAuth);
  };

  const handleInputChange = ({ target: { value, id } }) => {
    setFormState((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        value,
      },
    }));
  };

  const setEmailError = () =>
    setFormState((prevState) => ({
      ...prevState,
      email: {
        ...prevState.email,
        error: true,
        message: 'Enter a valid email (sally@example.com)',
      },
    }));

  const handleClearErrors = () => {
    setError(initialErrorState);
    setFormState((prev) => ({
      ...prev,
      email: {
        ...prev.email,
        error: false,
        message: '',
      },
    }));
  };

  const handleValidateEmail = ({ target: { value: email } }) => {
    if (!email) return;
    const validEmail = getHasValidEmail({ email });
    !validEmail && setEmailError();
  };

  useEffect(() => {
    if (router.isReady) {
      // esp (enable shopping products) is a query param used to set canPurchaseNodes in the member table. This boolean can override the isAvailableInApp product flag allowing special invited members to purchase nodes when other members can not.
      const canPurchaseNodes =
        router.query.esp !== undefined && router.query.esp === 'on';
      setEspEnabled(canPurchaseNodes);

      localStorage.setItem('esp', canPurchaseNodes);
    }
  }, [router.isReady]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password, rememberCheck } = formState;
    rememberCheck && localStorage.setItem('email', email.value);

    // Validate email before submitting
    const validEmail = getHasValidEmail({ email: email.value });
    if (!validEmail) return setEmailError();

    setIsLoading(true);
    if (rememberCheck) {
      setCreateRememberMeToken(true);
    }

    await signin({
      email: email.value.trim(),
      password: password.value,
      method: 'GET',
      sendEmail: useEmail2fa,
    })
      .then(async (session) => {
        if (session.resolver) {
          // Init 2FA flow
          setSessionState({
            session,
            show2FAModal: true,
          });
        }
      })
      .catch((err) => {
        const errorDetailMessage = err?.error?.detail || err?.error?.ErrorDetail || '';
        // If external auth app enabled - api returns error for code to be entered
        if (errorDetailMessage?.includes('Authenticator Pin Required')) {
          return setAppAuth({
            appEnabled: true,
            userToken: err?.userToken,
            show2FAModal: true,
            type: 'authenticator',
          });
        } else if (errorDetailMessage?.includes('Email Pin Required')) {
          return setAppAuth({
            appEnabled: true,
            userToken: err?.userToken,
            show2FAModal: true,
            type: 'email',
          });
        }
        // email & password failed errors
        const message = err.error?.message || err.message;
        if (message === 'Email not verified') {
          setError({
            ...errorMessages.unverified,
            message: (
              <>
                <Button
                  variant="text"
                  color="error"
                  onClick={handleDidNotReceiveEmail}
                  data-core-qa="errorButton"
                >
                  Send confirmation again
                  <ArrowRightLong className={styles.arrowIconErr} />
                </Button>
              </>
            ),
          });
        } else if (
          // regardless of type of credential error, vague response for user to check their info and try again or reset password
          message?.includes('invalid-login-credentials') ||
          message?.includes('wrong-password') ||
          message?.includes('not-found')
        ) {
          setError(errorMessages.creds);
        } else if (message?.includes('user-disabled')) {
          setError(errorMessages.accountLocked);
        } else if (message?.includes('too-many-requests')) {
          setError(errorMessages.tempDisabled);
        } else {
          setError(errorMessages.generic);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleDidNotReceiveEmail = () => {
    sendVerificationEmail({
      email: formState.email.value,
      link: `${window.location.origin}/email-confirmation`,
    })
      .then(() =>
        setError({
          error: true,
          severity: 'info',
          header: 'Email Sent.',
          body: 'We have sent another email. Please check your spam folder if you don’t see it in your inbox.',
        })
      )
      .catch(() => {
        setError({
          error: true,
          header: 'Unexpected Error.',
          body: 'Unable to send email at this time.',
        });
      });
  };

  const isDisabled =
    formState.email.error ||
    !formState.email.value ||
    !formState.password.value ||
    error.error;

  const queryParam = espEnabled ? { esp: 'on' } : {};

  return (
    <Box
      component="section"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        background: `url(${config.brand.loginImage})`,
        overflow: 'auto',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '@media (max-width: 500px)': {
          alignItems: 'flex-start',
          paddingTop: '40px',
        },
      }}
    >
      <Paper
        elevation={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '488px',
          height: 'fit-content',
          '@media (max-width: 500px)': {
            width: 'calc(100% - 40px)',
          },
        }}
      >
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.logoContainer}>
            <span>
              <a
                data-core-qa="loginHomeIcon"
                onClick={() => router.push(brand.loggedOutHomeRoute)}
                className={styles.logo}
              >
                <img
                  src={brand.brandLogoSymbolLight}
                  alt={`${brand.brandName} Logo`}
                  width="auto"
                  height="50"
                />
              </a>
            </span>
          </div>
          <div className={styles.welcome}>
            <Typography variant="h2" data-core-qa="loginTitleText">
              {isShopping ? 'Please Log in to Continue' : 'Login to Your Account'}
            </Typography>
            <Typography
              variant="subtitle2"
              className={styles.subHeader}
              data-core-qa="loginTitleSubText"
            >
              Welcome back! Please enter your details.
            </Typography>
            {error.error ? (
              <div className={styles.errorMessageContainer}>
                <Alert variant="outlined" severity={error.severity || 'error'}>
                  <AlertBody header={error.header} body={error.body}>
                    {error.message ? error.message : null}
                  </AlertBody>
                </Alert>
              </div>
            ) : null}
          </div>
          <TextField
            id="email"
            type="text"
            autoComplete="email"
            fullWidth
            size="small"
            error={formState.email.error}
            helperText={formState.email.error && formState.email.message}
            label="Email Address"
            onChange={handleInputChange}
            onBlur={handleValidateEmail}
            onFocus={handleClearErrors}
            value={formState.email.value || undefined}
            data-core-qa="loginInputEmail"
            placeholder="email@example.com"
          />
          <div className={styles.input}>
            <TextField
              id="password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              sizing="large"
              error={formState.password.error}
              helperText={formState.password.error && formState.password.message}
              label="Password"
              onChange={handleInputChange}
              onFocus={handleClearErrors}
              value={formState.password.value || undefined}
              data-core-qa="loginInputPassword"
              placeholder="Password"
              fullWidth
              size="small"
              InputProps={{
                endAdornment: (
                  <PasswordInputIcon state={showPassword} setState={setShowPassword} />
                ),
              }}
            />
          </div>
          <div className={styles.formRow}>
            {featureFlags?.BypassTokens && (
              <FormControlLabel
                label="Remember me"
                labelPlacement="end"
                control={
                  <Checkbox
                    id="loginCheckboxRememberMe"
                    data-core-qa="loginCheckboxRememberMe"
                    size="small"
                    onChange={(e) => handleRememberMe(e, setFormState)}
                    checked={formState.rememberCheck}
                  />
                }
              />
            )}
            <Link href={routes.forgotPassword.path}>
              <a className={styles.link} data-core-qa="loginButtonForgotPassword">
                Forgot Password
              </a>
            </Link>
          </div>
          <Button
            fullWidth
            variant="contained"
            type="submit"
            disabled={isDisabled}
            data-core-qa="loginButtonSubmit"
            size="large"
            color="primary"
          >
            {isLoading ? <LoadingSpinner /> : 'Login'}
          </Button>
        </form>
        <div className={styles.row}>
          {!featureFlags['Membership.Signup.RestrictToReferral'] ? (
            <Typography variant="body2Dark">Don&apos;t have an account?</Typography>
          ) : null}
          <Link href={{ pathname: routes.signup.path, query: queryParam }}>
            <a className={styles.link} data-core-qa="loginButtonNoAccount">
              {featureFlags['Membership.Signup.RestrictToReferral']
                ? `Don't have an account?`
                : `Sign Up`}
              <ArrowRightLong className={styles.arrowIcon} />
            </a>
          </Link>
        </div>
        <div className={styles.row} data-core-qa="loginHelpText">
          <Typography variant="body2Dark"> Need Help?</Typography>
          <a
            className={styles.link}
            href={routes.support.path}
            target="_blank"
            rel="noreferrer"
            data-core-qa="loginPageNeedHelpLinkText"
          >
            Contact Support
          </a>
        </div>
      </Paper>
      <Dialog
        open={sessionState.show2FAModal || appAuth.show2FAModal}
        onClose={close2FAModal}
      >
        <TwoFactorAuthModal
          session={sessionState.session}
          appAuth={appAuth}
          close={close2FAModal}
          callback={handleSetLoginQuery}
        />
      </Dialog>
    </Box>
  );
}

Login.strictlyPublicRoute = true;
